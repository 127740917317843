import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Comment from "@material-ui/icons/Comment";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { Button } from "@material-ui/core";

import SearchAccordion from "../SearchAccordion";

import api from "../../services/api";

import TicketsTagSelect from "../TicketsTagSelect";
import TicketsProductSelect from "../TicketsProductSelect";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },
  accordionSlot: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },
  ticketOptionsBoxSearch: {
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1)
  },
  buttonFilter:{
    display: 'flex',
    height: 20,
    marginLeft: 6,
    marginRight: 6,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    justifyContent: "left"
  },
  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [tagList, setTags] = useState([]);
  const [productList, setProducts] = useState([]);

  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const [filtros, setFiltros] = useState([]);
  const [reprocessar, setReprocessar] = useState(false);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "SUPERADMIN") {
      setShowAllTickets(false);
    }

    const fetchData = async () => {
		  try {
			  const { data } = await api.get("/tags");
			  setTags(data.tags);

        const getProducts = await api.get("/products");
			  setProducts(getProducts.data.products);

		  } catch (err) {
        console.log(err)
		  }
		};
	
		fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      setFiltros([]);
    }

  }, [tab]);

  let searchTimeout;
/*
  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };*/

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const adicionarFiltro = (filtro) => {
    const index = filtros.findIndex(item => Object.keys(item)[0] === Object.keys(filtro)[0]);
    if (index !== -1) {
      setFiltros([
        ...filtros.slice(0, index),
        filtro,
        ...filtros.slice(index + 1)
      ]);
    } else {
      setFiltros([...filtros, filtro]);
    }
  }

  const handleSearch = (searchValue) => {
    if(searchValue.reprocessar !== undefined){
      const filtro = {reprocessar: {texto: 'reprocessar', valor: {reprocessar}, tipoFiltro: 'reprocessar'}};
      adicionarFiltro(filtro)
      removerFiltro("reprocessar")
    }else{
      adicionarFiltro(searchValue);
    }
    
  }
  const removerFiltro = (filtro) => {
    setFiltros(filtros.filter(f => Object.keys(f)[0] !== filtro));
  }

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<Comment />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
						value={"closed"}
						icon={<CheckBoxIcon />}
						label={i18n.t("tickets.tabs.closed.title")}
						classes={{ root: classes.tab }}
					/>
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={tab === "search" ? classes.ticketOptionsBoxSearch : classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.accordionSlot}>
             <SearchAccordion onSearch={handleSearch}  />
             <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "12px", marginTop: "10px" }}>
                 {filtros.map((filtro, index) => (
                      Object.entries(filtro).map(([key, value]) => (
                        <Button
                          key={`${key}-${value.valor}`} // adiciona a chave única aqui
                          variant="contained"
                          color="secondary"
                          onClick={() => removerFiltro(key)}
                          title={`Remover: ${key.replace(/_/g, " ")} ${value.texto}`}
                          className={classes.buttonFilter}
                          startIcon={<ClearIcon />}
                        >
                       <span>{key.replace(/_/g, " ")}:&nbsp;</span>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {`${value.texto}`}
                        </span>
                        <span className="Button::after"></span>
                        </Button>
                      ))
                   
                  ))}
             </div>
          </div>
          
        ) : (
          <>
            <Button
              variant="outlined"
              style={{fontSize: "0.8rem"}}
              color="primary"
              onClick={() => setNewTicketModalOpen(true)}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>
            <Can
              role={user.profile}
              perform="tickets-manager:showall"
              yes={() => (
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              )}
            />
            <div style={{ display: "flex" }}>
              <TicketsProductSelect
                style={{ paddingLeft: 6 }}
                selectedProductIds={selectedProductIds}
                userQueues={user?.queues}
                onChange={(values) => setSelectedProductIds(values == 0 ? [] : [values])}
              />
            <TicketsTagSelect
                style={{ paddingLeft: 6 }}
                selectedTagIds={selectedTagIds}
                userQueues={user?.queues}
                onChange={(values) => setSelectedTagIds(values == 0 ? [] : [values])}
              />
            </div>
          </>
        )}

      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            tagList={tagList}
            productList={productList}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedTagIds={selectedTagIds}
            selectedProductIds={selectedProductIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          
          <TicketsList
            status="pending"
            tagList={tagList}
            productList={productList}
            selectedQueueIds={selectedQueueIds}
            selectedTagIds={selectedTagIds}
            selectedProductIds={selectedProductIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
				<TicketsList
					status="closed"
          tagList={tagList}
          productList={productList}
					showAll={true}
					selectedQueueIds={selectedQueueIds}
          selectedTagIds={selectedTagIds}
          selectedProductIds={selectedProductIds}
				/>
			</TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TicketsList
            tab={tab}
            filtros={filtros}
            tagList={tagList}
            productList={productList}
            reprocessar={reprocessar}
            selectedTagIds={selectedTagIds}
            selectedProductIds={selectedProductIds}
          />
      </TabPanel>
 
    </Paper>
  );
};

export default TicketsManager;
