import React, { useState, useEffect } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")}
				</Typography>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Tipo Lampzap
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="tipoLampzap-setting"
						name="tipoLampzap"
						value={
							settings && settings.length > 0 && getSettingValue("tipoLampzap")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="loja">
							Loja 
						</option>
						<option value="site">
							Site
						</option>
					</Select>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Tipo Distribuição
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="sistemaDistribuicao-setting"
						name="sistemaDistribuicao"
						value={
							settings && settings.length > 0 && getSettingValue("sistemaDistribuicao")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							Com distribuição (Um por Um) 
						</option>
						<option value="disabled">
							Sem distribuição
						</option>
					</Select>
				</Paper>
				<Paper className={classes.paper}>
				<Typography variant="body1">
					Qntd. Dias Distribuição
				</Typography>
				<TextField
					margin="dense"
					variant="outlined"
					id="sistemaDistribuicaoDias-setting"
					name="sistemaDistribuicaoDias"
					value={
					settings && settings.length > 0 && getSettingValue("sistemaDistribuicaoDias")
					}
					className={classes.settingOption}
					onChange={handleChangeSetting}
				/>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userCreation.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="userCreation"
						value={
							settings && settings.length > 0 && getSettingValue("userCreation")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							{i18n.t("settings.settings.userCreation.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userCreation.options.disabled")}
						</option>
					</Select>
				</Paper>
							
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Tipo Linha
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="tipoLinha-setting"
						name="tipoLinha"
						value={
							settings && settings.length > 0 && getSettingValue("tipoLinha")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							Linha Atribuida
						</option>
						<option value="disabled">
							Linha Aleatória
						</option>
					</Select>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Tipo Horário Ausência
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="tipoAusencia-setting"
						name="tipoAusencia"
						value={
							settings && settings.length > 0 && getSettingValue("tipoAusencia")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="padrao1">
							Seg-Sexta [9h-18h] / Sab. [9h-13h]
						</option>
						<option value="padrao2">
							Seg-Sexta [9h-19h] / Sab. [9h-13h]
						</option>
						<option value="padrao3">
							Seg-Sexta [9h-20h] / Sab. [9h-13h]
						</option>
						<option value="padrao4">
							Seg-Sexta [9h-21h] / Sab. [9h-13h]
						</option>
						<option value="padrao5">
							Seg-Sexta [9h-22h] / Sab. [9h-13h]
						</option>
						<option value="padrao6">
							Seg-Sexta [8h-18h] / Sab. [9h-13h]
						</option>
						<option value="padrao7">
							Seg-Sexta [8h-19h] / Sab. [9h-13h]
						</option>
						<option value="padrao8">
							Seg-Sexta [8h-20h] / Sab. [9h-13h]
						</option>
						<option value="padrao9">
							Seg-Sexta [8h-21h] / Sab. [9h-13h]
						</option>
						<option value="padrao10">
							Seg-Sexta [8h-22h] / Sab. [9h-13h]
						</option>
						<option value="padrao11">
							Seg-Sexta [8h-18h] / Sab. [8h-13h]
						</option>
						<option value="padrao12">
							Seg-Sexta [8h-19h] / Sab. [8h-13h]
						</option>
						<option value="padrao13">
							Seg-Sexta [8h-20h] / Sab. [8h-13h]
						</option>
						<option value="padrao14">
							Seg-Sexta [8h-21h] / Sab. [8h-13h]
						</option>
						<option value="padrao15">
							Seg-Sexta [8h-22h] / Sab. [8h-13h]
						</option>
						<option value="nenhum">
							Sem mensagem de ausência
						</option>
					</Select>
				</Paper>	
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Bloq. Atribuição 30s
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="cooldown-setting"
						name="cooldown"
						value={
							settings && settings.length > 0 && getSettingValue("cooldown")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							Ativado
						</option>
						<option value="disabled">
							Desativado
						</option>
					</Select>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Ordenação de tickets:
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="tipoOrdenacao-setting"
						name="tipoOrdenacao"
						value={
							settings && settings.length > 0 && getSettingValue("tipoOrdenacao")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="0">
							Antigo
						</option>
						<option value="1">
							Novo
						</option>
					</Select>
				</Paper>
				<Paper className={classes.paper}>
				<Typography variant="body1">
					Limite de etiquetas
				</Typography>
				<TextField
					margin="dense"
					variant="outlined"
					id="tagLimit-setting"
					name="tagLimit"
					value={
					settings && settings.length > 0 && getSettingValue("tagLimit")
					}
					className={classes.settingOption}
					onChange={handleChangeSetting}
				/>
				</Paper>
				<Paper className={classes.paper}>
				<Typography variant="body1">
					Limite de tabulação
				</Typography>
				<TextField
					margin="dense"
					variant="outlined"
					id="reasonLimit-setting"
					name="reasonLimit"
					value={
					settings && settings.length > 0 && getSettingValue("reasonLimit")
					}
					className={classes.settingOption}
					onChange={handleChangeSetting}
				/>
				</Paper>
				<Typography variant="body2" gutterBottom>
					{"Limites de template"}
				</Typography>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Primeiro Contato
					</Typography>
					<TextField
						margin="dense"
						variant="outlined"
						id="templateLimitFirstContact-setting"
						name="templateLimitFirstContact"
						value={
							settings && settings.length > 0 && getSettingValue("templateLimitFirstContact")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					/>
				</Paper>
				
				<Paper className={classes.paper}>
					<Typography variant="body1">
						Retorno Contato
					</Typography>
					<TextField
						margin="dense"
						variant="outlined"
						id="templateLimitContactReturn-setting"
						name="templateLimitContactReturn"
						value={
							settings && settings.length > 0 && getSettingValue("templateLimitContactReturn")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					/>
				</Paper>
			</Container>
		</div>
	);
};

export default Settings;
