import React,  { useContext, useState }  from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles } from '@material-ui/core';
import { FilterList, AssignmentInd, Person, Phone, ConfirmationNumber, HowToVote, HeadsetMic, HourglassFull, Event, SettingsBackupRestore, LabelRounded, WrapText } from '@material-ui/icons';
import { Button } from "@material-ui/core";
import SearchModal from '../SearchModal';
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    summary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: 10
    },
    summaryContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    icon: {
      marginRight: 8,
    },
    details: {
      display: 'block'
    }
  }));


function SearchAccordion( props ) {
   
      const classes = useStyles();
      const [searchModalOpen, setSearchModalOpen] = useState(false);
      const [tipoFiltro, setTipoFiltro] = useState(null);
      const [textoFiltro, setTextoFiltro] = useState(null);
      const { user } = useContext(AuthContext);
      const [initialValuesSearch, setInitialValuesSearch] = useState(null);


      const handleSearchSubmit = (search, selectedValue) => {
        setTipoFiltro(null); 
        setTextoFiltro(null);
        if (search[tipoFiltro] !== undefined) {
          if(!(search[tipoFiltro].length === 0)){
            const filtro = {[tipoFiltro]: {valor: search[tipoFiltro], texto: search[tipoFiltro], tipoFiltro: tipoFiltro }};
            if (tipoFiltro === "VENDEDOR") {
              filtro[tipoFiltro].texto = selectedValue;
            }else if (tipoFiltro === "FILTRAR_ETIQUETAS") {
              filtro[tipoFiltro].texto = selectedValue;
            }else if (tipoFiltro === "FILTRAR_PRODUTOS") {
              filtro[tipoFiltro].texto = selectedValue;
            }else if (tipoFiltro === "TEMPO_S_INTERACAO"){
              filtro[tipoFiltro].texto = `${search[tipoFiltro][0]} a ${search[tipoFiltro][1]} minutos`;
            }else if (tipoFiltro === "DATA_ULTIMA_MENSAGEM"){
              const inputDateInicial = new Date(search[tipoFiltro][0]); 
              const dataInical = `${inputDateInicial.getDate().toString().padStart(2, '0')}/${(inputDateInicial.getMonth() + 1).toString().padStart(2, '0')}/${inputDateInicial.getFullYear()} ${inputDateInicial.getHours().toString().padStart(2, '0')}:${inputDateInicial.getMinutes().toString().padStart(2, '0')}`;
              const inputDateFinal = new Date(search[tipoFiltro][1]); 
              const dataFinal = `${inputDateFinal.getDate().toString().padStart(2, '0')}/${(inputDateFinal.getMonth() + 1).toString().padStart(2, '0')}/${inputDateFinal.getFullYear()} ${inputDateFinal.getHours().toString().padStart(2, '0')}:${inputDateFinal.getMinutes().toString().padStart(2, '0')}`;



              filtro[tipoFiltro].texto = `${dataInical} até ${dataFinal}`;
            }
       
            const searchParams = {...props.searchParams, ...filtro};
            props.onSearch(searchParams);
          }

        }
      };
    
      const handleOpenReSearch= () => {   
    
        props.onSearch({ reprocessar: true});
        
    };

      const handleOpenSearchModal = (props) => {   
      
     
          setInitialValuesSearch({
            [props.funcao]: ""
          });
       
      
        setTipoFiltro(props.funcao); 
        setTextoFiltro(props.text);
        setSearchModalOpen(true);    
      
      };
    
      const handleCloseSearchModal = () => {
        setSearchModalOpen(false);
        setTipoFiltro(null); 
        setTextoFiltro(null);
      };
      const BotaoAcao = (props) => {
        
        return (
          <Button disabled={props.permissao !== undefined ? (!user.profile.toUpperCase().includes(props.permissao)) : false} variant="contained" style={{ width: "100%", height: "46px" }} onClick={() =>  { handleOpenSearchModal(props)} }>
              <props.icon />
            {props.text}
          </Button>
        );
    }; 
      const BotaoReload = (props) => {
          
        return (
          <Button disabled={props.permissao !== undefined ? (!user.profile.toUpperCase().includes(props.permissao)) : false} variant="outlined" style={{ width: "100%", height: "30px", marginLeft: "50%" }} onClick={() =>  { handleOpenReSearch()} }>
              <props.icon />
            {props.text}
          </Button>
        );
    };
    
  return (

    <Accordion>
       <AccordionSummary className={classes.summary} expandIcon={null} classes={{
          content: classes.summaryContent,
        }}>
        <FilterList className={classes.icon} />
        <Typography variant="h6">Filtrar</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details} >
       
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 3fr)", gap: "12px"}}>
        <BotaoAcao text="CPF" funcao="CPF" icon={AssignmentInd} />
        <BotaoAcao text="NOME" funcao="NOME" icon={Person} />
        <BotaoAcao text="TELEFONE" funcao="TELEFONE" icon={Phone} />
        <BotaoAcao text="TICKET" funcao="TICKET" icon={ConfirmationNumber} />
        <BotaoAcao text="STATUS TICKET" funcao="STATUS_TICKET" icon={HowToVote} />
        <BotaoAcao text="VENDEDOR" funcao="VENDEDOR" permissao="ADMIN" icon={HeadsetMic} />
        <BotaoAcao text="TEMPO S/ INTERAÇÃO" funcao="TEMPO_S_INTERACAO" icon={HourglassFull} />
        <BotaoAcao text="DATA ÚLTIMA MENSAGEM" funcao="DATA_ULTIMA_MENSAGEM" icon={Event} />
        <BotaoAcao text="FILTRAR ETIQUETA" funcao="FILTRAR_ETIQUETAS" icon={LabelRounded} />
        <BotaoAcao text="FILTRAR PRODUTO" funcao="FILTRAR_PRODUTOS" icon={LabelRounded} />
      </div>
      <div style={{ display: "grid", width: "100%", gridTemplateColumns: "repeat(2, 3fr)", gap: "12px", marginTop: "16px", marginLeft:"1%"}}>
        <BotaoReload text="REPROCESSAR" funcao="REPROCESSAR" icon={SettingsBackupRestore} />
      </div>
            
      </AccordionDetails>
      <SearchModal open={searchModalOpen} textoFiltro={textoFiltro} tipoFiltro={tipoFiltro} initialValues={initialValuesSearch} onClose={handleCloseSearchModal} handleSearchSubmit={handleSearchSubmit} />

    </Accordion>
  );
}

export default SearchAccordion;